<template>
  <div class="deposit-page">
    <div class="deposit-page__body">
      <div class="deposit-grid deposit-grid_coin">
        <div
          v-for="payment in items.coinPayments.payments"
          :key="payment.name"
          class="deposit-grid__cell"
          :class="getClassModifier(payment.name)"
        >
          <a href="#" @click.prevent="activePaymentMethod = 'coinPayments'">
            <img :src="getImageUrl(payment.logo)" :alt="payment.alt" />
          </a>
        </div>
      </div>

      <div class="deposit-grid deposit-grid_bank" v-if="settings && settings.paypal_enabled">
        <!-- <div class="deposit-grid deposit-grid_bank"> -->
        <div
          v-for="payment in items.paypal.payments"
          :key="payment.name"
          class="deposit-grid__cell"
          :class="getClassModifier(payment.name)"
        >
          <a href="#" @click.prevent="activePaymentMethod = 'paypal'">
            <img :src="getImageUrl(payment.logo)" :alt="payment.alt" />
          </a>
        </div>
      </div>

      <div class="deposit-grid deposit-grid_coin" v-if="userInfo.id == 1">
        <div
          v-for="payment in items.freeKassa.payments"
          :key="payment.name"
          class="deposit-grid__cell"
          :class="getClassModifier(payment.name)"
        >
          <a href="#" @click.prevent="activePaymentMethod = 'freeKassa'">
            <img :src="getImageUrl(payment.logo)" :alt="payment.alt" />
          </a>
        </div>
      </div>

      <div class="deposit-grid deposit-grid_coin" v-if="userInfo.id == 1 && payeerForm.length > 0">
        <div
          v-for="payment in items.payeer.payments"
          :key="payment.name"
          class="deposit-grid__cell"
          :class="getClassModifier(payment.name)"
        >
          <a href="#" @click.prevent="activePaymentMethod = 'payeer'">
            <img :src="getImageUrl(payment.logo)" :alt="payment.alt" />
          </a>
        </div>
      </div>
    </div>

    <b-modal :active.sync="activePaymentMethod" :width="610" scroll="keep">
      <div class="deposit-page__modal">
        <h3 class="deposit-page__modal-title" @click="handleAnimate()">Choose amount</h3>
        <ul class="deposit-page__modal-list" v-if="activePaymentMethod">
          <!-- <p>{{ activePaymentMethod }}</p> -->
          <li
            v-for="(item, index) of items[activePaymentMethod].tariff"
            :class="['deposit-page__modal-item', { 'deposit-page__modal-item_disable': possibleReplenishmentAmount && item.summ > possibleReplenishmentAmount }]"
            :key="item.summ"
            @click="!(possibleReplenishmentAmount && item.summ > possibleReplenishmentAmount) && handleBuy(activePaymentMethod == 'payeer' ? payeerForm[index] : item, activePaymentMethod)"
          >
            <a>
              <span class="deposit-page__modal-price">
                €{{ item.summ }}
                <span v-if="item.bonus">
                  &nbsp;
                  +
                  &nbsp;
                  <span
                    :class="['deposit-page__modal-bonus', `deposit-page__${item.style}`]"
                  >€{{ item.bonus }}</span>
                  &nbsp;
                  = €{{ item.summ + item.bonus}}
                </span>
              </span>

              <div
                :class="['deposit-page__modal-button', { 'deposit-page__modal-button_disable': !item.bonus }]"
              >{{ item.bonus ? "Bonus" : "No bonus"}}</div>

              <span :class="['deposit-page__modal-percent', `deposit-page__${item.style}`]">
                {{ item.percent }}
                <span class="deposit-page__modal-percent-sumbol">%</span>
              </span>
            </a>
          </li>
        </ul>

        <AppError v-if="error" style="margin-bottom: 1rem" :error="error" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import $ from "jquery";

import { cookieDomen } from "@/utils/constants";

import { ApiService } from "@/services/api.service";
import { BASE_URL } from "@/utils/constants";

import AppError from "@/components/app/root/AppError";

export default {
  components: { AppError },

  data: () => ({
    // isPaymentModalActive: false,
    activePaymentMethod: null,

    settings: {},
    payeerForm: [],

    error: null,

    items: {
      freeKassa: {
        payments: [
          {
            name: "free-ksassa",
            logo: "free-ksassa",
            alt: "Free-Kassa"
          },
          {
            name: "visa",
            logo: "visa",
            alt: "Visa"
          },
          {
            name: "mc",
            logo: "mc",
            alt: "Mastercard"
          },
          {
            name: "american_express",
            logo: "american_express",
            alt: "American Express"
          },
          {
            name: "maestro",
            logo: "Maestro",
            alt: "Maestro"
          }
        ],

        tariff: [
          {
            summ: 3,
            bonus: 0,
            percent: 0,
            style: "greey",
            hash: "477c7f8800dd624ac4f0da607bbb3e95"
          },
          {
            summ: 10,
            bonus: 1,
            percent: 10,
            style: "yellow",
            hash: "4556436f6c77d8a401dbc3f6e76852bb"
          },
          {
            summ: 25,
            bonus: 5,
            percent: 20,
            style: "orange",
            hash: "c63f31a31a05cef5d0daa04e121ca6d5"
          },
          {
            summ: 50,
            bonus: 15,
            percent: 30,
            style: "orange",
            hash: "33e98bb5df9c09719b03244081fd5af7"
          },
          {
            summ: 100,
            bonus: 40,
            percent: 40,
            style: "red",
            hash: "ea431a9db3a3fe53c8dad423988c3a29"
          },
          {
            summ: 300,
            bonus: 150,
            percent: 50,
            style: "red",
            hash: "00908962f132df0e59f5d049e8a94f21"
          }
        ]
      },

      coinPayments: {
        payments: [
          {
            name: "coin",
            logo: "coin-payments",
            alt: "Coin Payments"
          },
          {
            name: "monero",
            logo: "monero",
            alt: "Monero"
          },
          {
            name: "zcash",
            logo: "zcash",
            alt: "Zcash"
          },
          {
            name: "bitcoin",
            logo: "bitcoin",
            alt: "Bitcoin"
          },
          {
            name: "bitcoin-cash",
            logo: "cashcoin",
            alt: "Bitcoin Cash"
          },
          {
            name: "etherum",
            logo: "ethereum",
            alt: "Ethereum"
          },
          {
            name: "litecoin",
            logo: "litecoin",
            alt: "Litecoin"
          }
        ],

        tariff: [
          {
            summ: 3,
            bonus: 0,
            percent: 0,
            style: "greey"
          },
          {
            summ: 10,
            bonus: 1,
            percent: 10,
            style: "yellow"
          },
          {
            summ: 25,
            bonus: 5,
            percent: 20,
            style: "orange"
          },
          {
            summ: 50,
            bonus: 15,
            percent: 30,
            style: "orange"
          },
          {
            summ: 100,
            bonus: 40,
            percent: 40,
            style: "red"
          },
          {
            summ: 300,
            bonus: 150,
            percent: 50,
            style: "red"
          }
        ]
      },

      paypal: {
        payments: [
          {
            name: "paypal",
            logo: "paypal",
            alt: "PayPal"
          },
          {
            name: "visa",
            logo: "visa",
            alt: "Visa"
          },
          {
            name: "mc",
            logo: "mc",
            alt: "Mastercard"
          },
          {
            name: "american_express",
            logo: "american_express",
            alt: "American Express"
          },
          {
            name: "maestro",
            logo: "Maestro",
            alt: "Maestro"
          }
        ],

        tariff: [
          {
            summ: 3,
            bonus: 0,
            taxf: 0.59,
            percent: 0,
            style: "greey"
          },
          {
            summ: 10,
            bonus: 1,
            taxf: 0.99,
            percent: 10,
            style: "yellow"
          },
          {
            summ: 25,
            bonus: 5,
            taxf: 1.49,
            percent: 20,
            style: "orange"
          }
        ]
      },

      payeer: {
        payments: [
          {
            name: "payeer",
            logo: "payeer",
            alt: "PAYEER"
          },
          {
            name: "visa",
            logo: "visa",
            alt: "Visa"
          },
          {
            name: "mc",
            logo: "mc",
            alt: "Mastercard"
          },
          {
            name: "american_express",
            logo: "american_express",
            alt: "American Express"
          },
          {
            name: "maestro",
            logo: "Maestro",
            alt: "Maestro"
          }
        ],

        tariff: [
          {
            summ: 3,
            bonus: 0,
            percent: 0,
            style: "greey"
          },
          {
            summ: 10,
            bonus: 1,
            percent: 10,
            style: "yellow"
          },
          {
            summ: 25,
            bonus: 5,
            percent: 20,
            style: "orange"
          },
          {
            summ: 50,
            bonus: 15,
            percent: 30,
            style: "orange"
          },
          {
            summ: 100,
            bonus: 40,
            percent: 40,
            style: "red"
          },
          {
            summ: 300,
            bonus: 150,
            percent: 50,
            style: "red"
          }
        ]
      }
    }
  }),

  computed: {
    ...mapState("auth", ["userInfo"]),

    isPaypalDeposit() {
      return this.activePaymentMethod === "paypal";
    },

    possibleReplenishmentAmount() {
      if (!this.isPaypalDeposit) return null;

      const paypal_deposit = this.userInfo.paypal_deposit;

      if (paypal_deposit < 10) {
        return 3;
      } else if (paypal_deposit < 25) {
        return 10;
      } else if (paypal_deposit < 50) {
        return 25;
      } else if (paypal_deposit < 100) {
        return 50;
      } else if (paypal_deposit < 300) {
        return 100;
      } else {
        return null;
      }
    },

    renderTatiff() {
      if (this.activePaymentMethod === "paypal") {
        // return this.paypalTariff.filter(
        //   item => item.summ < this.userInfo.paypal_deposit
        // );

        return this.paypalTariff;

        // this.userInfo.paypal_deposit;
        // if (this.userInfo.paypal_deposit < 3) return this.paypalTariff;
      } else if (
        this.activePaymentMethod === "coinPayments" ||
        this.activePaymentMethod === "freeKassa" ||
        this.activePaymentMethod === "payeer"
      )
        return this.coinPaymentsTariff;
    }
  },

  mounted() {
    console.log("mountedmountedmountedmounted");
    this.loadSetting();
    this.loadPayeerForm();
  },

  watch: {
    activePaymentMethod() {
      this.error = null;
    }
  },

  methods: {
    getImageUrl: function(logoImage) {
      return require("../assets/img/logo-payments/" + logoImage + ".png");
    },
    getClassModifier: function(name) {
      return "deposit-grid__cell_" + name;
    },

    async handleBuy(data, activePaymentMethod) {
      await this.loadSetting();

      if (this.settings.engineering_mode) {
        this.error = "Service in enginner mode. Come back later.";
        this.handleAnimate();

        return;
      } else if (
        this.activePaymentMethod === "paypal" &&
        this.userInfo.paypal_today_count >= 2
      ) {
        this.error = "Next deposit using PayPal you can make tomorrow.";
        this.handleAnimate();

        return;
      } else if (
        this.activePaymentMethod === "paypal" &&
        !this.settings.paypal_enabled
      ) {
        this.error = "Service is currently unavailable. Come back later.";
        this.handleAnimate();

        return;
      }

      this.error = null;

      // alert(activePaymentMethod);

      let form = null;

      if (
        activePaymentMethod == "coinPayments" ||
        activePaymentMethod == "paypal"
      ) {
        let allowСurrencies =
          activePaymentMethod == "coinPayments"
            ? "BTC,ETH,LTC,BCH,XMR,ZEC"
            : "paypal";

        form = $(
          `<form action="https://www.coinpayments.net/index.php" method="post">
          <input type="hidden" name="cmd" value="_pay_simple">
          <input type="hidden" name="reset" value="1">
          <input type="hidden" name="merchant" value="cc711b11fce3e0831404f3c4625300a3">
          <input type="hidden" name="item_name" value="Buy Credit">
          <input type="hidden" name="item_desc" value="${this.userInfo.email}">
          <input type="hidden" name="email" value="${this.userInfo.email}">
          <input type="hidden" name="currency" value="EUR">
          <input type="hidden" name="allow_currencies" value="${allowСurrencies}">	
          <input type="hidden" name="amountf" value="${data.summ}">
          ${
            data.taxf
              ? // activePaymentMethod !== "coinPayments"
                `<input type="hidden" name="taxf" value="${data.taxf}">`
              : ``
          }
          <input type="hidden" name="want_shipping" value="0">
        </form>`
        );
      } else if (activePaymentMethod == "freeKassa") {
        form = $(
          `<form action="https://www.free-kassa.ru/merchant/cash.php" method="post">
          <input type="hidden" name="oa" value="${data.summ}">
          <input type="hidden" name="o" value="${this.userInfo.id}">
          <input type="hidden" name="us_desc" value="">
          <input type="hidden" name="s" value="${data.hash}">
          <input type="hidden" name="m" value="205183">
          <input type="hidden" name="us_uid" value="${this.userInfo.id}">
          <input type="hidden" name="us_email" value="${this.userInfo.email}">
        </form>`
        );
      } else if (activePaymentMethod == "payeer") {
        form = $(
          `<form action="https://payeer.com/merchant/" method="post">
          <input type="hidden" name="lang" value="${data.lang}">
          <input type="hidden" name="m_amount" value="${data.m_amount}">
          <input type="hidden" name="m_curr" value="${data.m_curr}">
          <input type="hidden" name="m_desc" value="${data.m_desc}">
          <input type="hidden" name="m_orderid" value="${data.m_orderid}">
          <input type="hidden" name="m_process" value="${data.m_process}">
          <input type="hidden" name="m_shop" value="${data.m_shop}">
           <input type="hidden" name="m_sign" value="${data.m_sign}">
        </form>`
        );
      }

      $("body").append(form);
      form.submit();
    },

    async loadSetting() {
      const requestData = {
        method: "get",
        url: `${BASE_URL}api/storage/settings/`
      };

      try {
        const response = await ApiService.customRequest(requestData);

        this.settings = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async loadPayeerForm() {
      const requestData = {
        method: "get",
        url: `${BASE_URL}webhook/payeer/get_form/`
      };

      try {
        const response = await ApiService.customRequest(requestData);

        console.log("payeerForm", response.data);

        this.payeerForm = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    handleAnimate() {
      let elem = document.querySelector(".modal-content");
      if (!elem.classList.contains("errorAminate")) {
        elem.classList.add("errorAminate");
      }
      setTimeout(() => {
        elem.classList.remove("errorAminate");
      }, 1300);
    }
  }
};
</script>

